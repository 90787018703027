<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<!-- 支付条件头部 -->
<script>
  import {
    getExamEasy,
  } from "@/api/admin/exam/examRecord.js"
  import {
    getWlgs,
  } from "@/api/zhengshu/manage.js";
  export default {
    components: {},
    props: {
      isShowXm: {
        type: Boolean,
        default: true
      },
      pageData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data: function() {
      return {
        examList: [],
        years: [],
        xmdm: [{
            name: "考试",
            val: "EXAM_PAY",
          },
          {
            name: "邮寄",
            val: "CERT_PAY",
          },

        ],
        ZskList: [],
      };
    },
    methods: {
      // 获取考试
      getExamList() {
        getExamEasy({
          ksnf: this.pageData.nf
        }).then(res => {
          if (res.status) {
            this.examList = res.data
            console.log(this.examList)
          }
        })
      },
      // 获取证书列表
      getZskList() {
        getWlgs({
          nf: this.pageData.nf
        }).then((res) => {
          if (res.status) {
            this.ZskList = res.data;
          }
        });
      },
      // 类别选择
      chooseItem() {
        if(this.pageData.nf){
          if (this.pageData.xmdm== "EXAM_PAY" ) {
            this.getExamList()
          }else {
            this.getZskList()
          }
        }

      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      getNewList(){

      },test(){

      }

    },
    mounted() {
      this.getYear()
      if (!this.isShowXm) {
        this.getZskList()
      }
    }
  }
</script>

<template>
  <!-- start page title -->
  <div style="display: contents;">
    <el-select class=" mr-2 " style="width:8%;" clearable placeholder="统计类型" v-model="pageData.xmdm" size="small"
               @change="chooseItem"  v-if="this.isShowXm">
      <el-option v-for="(item, i) in xmdm" :label="item.name" :value="item.val" :key="i">
      </el-option>
    </el-select>
    <el-select class=" mr-2 " style="width:7%;" clearable placeholder="年份" v-model="pageData.nf" size="small" @change="chooseItem">
      <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
      </el-option>
    </el-select>
    <div style="width:8%;" v-show="pageData.xmdm">
      <el-select class=" mr-2 " clearable placeholder="考试项目" size="small" v-model="pageData.ssdm"
        v-show="pageData.xmdm=='EXAM_PAY'">
        <el-option v-for="(item, i) in examList" :label="item.ksmc" :value="item.ksbmbh" :key="i">
        </el-option>
      </el-select>
      <el-select class=" mr-2 " v-show="pageData.xmdm=='CERT_PAY'" clearable placeholder="证书邮寄" size="small"
        v-model="pageData.ssdm">
        <el-option v-for="(item, i) in ZskList" :label="item.wlgs+'|'+item.qym" :value="item.qym" :key="i">
        </el-option>
      </el-select>
    </div>
    <el-date-picker v-model="pageData.kssj" type="datetime" size="small" style="width:9%;" class="mr-2 "
      placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
    <el-date-picker v-model="pageData.jssj" type="datetime" size="small" style="width: 9%;" class="mr-2 "
      placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
  </div>
  <!-- end page title -->
</template>
