<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style scoped>
  .msgName {
    min-width: 120px;
    width: 120px;
  }

  .msgVal {
    line-height: 20px;
    white-space: normal;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/.el-input__icon {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import payConditionHeader from "@/components/payCondition-header";
  import {
    getExamEasy,
  } from "@/api/admin/exam/examRecord.js"
  import {
    getSum,
  } from "@/api/statistics/statistics.js"
  import {
    getFeeRecord,
  } from "@/api/pay/record.js"
  /**
   *证书邮寄费用管理
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      payConditionHeader



    },
    data() {
      return {
        title: " 证书邮寄管理",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "证书邮寄",
            active: true
          }
        ],
        isShowXm:false,
        sumList:{},
        // 证书状态
        statusLsit: [{
            name: "已邮寄",
            val: "1",
          },
          {
            name: "已支付",
            val: "2",
          },
          {
            name: "未支付",
            val: "3",
          }, {
            name: "已退款",
            val: "0",
          },
        ],
        tableList:[],
        pageData2:{
          xmdm: "CERT_PAY",
          nf: new Date().getFullYear(),
        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          xmdm: "CERT_PAY",
          nf: new Date().getFullYear(),
          ssdm: "",
          kssj: "",
          jssj: "",
        }
      };
    },
    methods: {
      getSumList(){
        getSum(this.pageData2).then(res=>{
          if(res.status){
            this.sumList=res.data
          }
        })
      },
      searchClick(){
        this.pageData.pageNum=1
        this.getList()
      },
      getList() {
        getFeeRecord(this.pageData).then(res => {
          if (res.status) {
            if (res.status) {
              console.log(res)
              this.tableList = res.data
              this.pageData.total = res.total
            }
          }
        })
      },
      
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      
    },
    mounted() {
      this.getList()
      this.getSumList()
    }

  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <payConditionHeader :pageData="pageData" :isShowXm="isShowXm"></payConditionHeader>
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>

              </div>
<!--              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>-->
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered ">
                <thead class="thead-light">
                  <tr>
                    <th>总记录</th>
                    <th>支付成功</th>
                    <th>未支付</th>
                    <th>退费 </th>
                    <th>退费金额</th>
                    <th>支付宝退费</th>
                    <th>微信退费</th>
                    <th>其他退费</th>
                    <th>收入总金额</th>
                    <th>支付宝收费</th>
                    <th>微信收费</th>
                    <th>其他收费</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
              
                    <td>{{sumList.zjls}}</td>
                    <td>{{sumList.zfcgs}}</td>
                    <td>{{sumList.wzfs}}</td>
                    <td>{{sumList.tfs}}</td>
                    <td>{{sumList.tfje/100}}</td>
                    <td>{{sumList.zfbtfje/100}}</td>
                    <td>{{sumList.wxtfje/100}}</td>
                    <td>{{sumList.qttfje/100}}</td>
                    <td>{{sumList.zsrje/100}}</td>
                    <td>{{sumList.zfbsfje/100}}</td>
                    <td>{{sumList.wxsfje/100}}</td>
                    <td>{{sumList.qtsfje/100}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 5%;">订单号</th>
                    <th>订单名称</th>
                    <th style="width: 5%;">姓名</th>
                    <th style="width: 10%;">证件号码 </th>
                    <th style="width: 5%;">交易类型</th>
                    <th style="width: 5%;">费用</th>
                    <th style="width: 5%;">支付状态</th>
                    <th style="width: 5%;">支付方式</th>
                    <th style="width: 14%;">订单交易号</th>
                    <th style="width: 11%;">支付时间</th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-for="(item,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{item.ddh}} </td>
                    <td>{{item.ywbt}}</td>
                    <td>
                      <el-button type="text" class="blue-font">{{item.xm}}</el-button>
                    </td>
                    <td>{{item.sfzjh}}</td>
                    <td>支付</td>
                    <td>{{item.zje/100}}</td>
                    <td>
                      <div class="line1" v-if="item.ywfs=='01'">微信</div>
                      <div class="line1" v-if="item.ywfs=='02'">支付宝</div>
                      <div class="line1" v-if="item.ywfs=='03'">易宝</div>
                      <div class="line1" v-if="item.ywfs=='04'">线下_现金</div>
                      <div class="line1" v-if="item.ywfs=='06'">线下_单位</div>
                      <div class="line1" v-if="item.ywfs=='05'">减免</div>
                    </td>
                    <td>
                      <div class="line1">已缴费</div>
                                      
                    </td>
                    <td>{{item.jyh}}</td>
                    <td>{{item.createTime}}</td>
                    </td>
                  </tr>
                  
                  
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 切换库 -->

    <!-- 编辑 -->



    <!-- 弹窗结束 -->
  </Layout>
</template>
