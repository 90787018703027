// 支付

// 支付记录
import {
  getRequest,
} from "@/libs/axios";
// 订单分页查询
export const getFeeRecord = params => {
  return getRequest("/yethan/pay/feeRecord/listPage", params);
};
// 订单详情
export const  tradeDetails= params => {
  return getRequest("/yethan/pay/trade", params);
};
