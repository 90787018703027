// 统计
import {
  getRequest,
} from "@/libs/axios";

// 收费统计汇总
export const getSum= params => {
  return getRequest("/yethan/pay/stats/sum", params);
};

// 收费刷新统计结果
export const  refreshPayCord= params => {
  return getRequest("/yethan/pay/stats/doStats", params);
};

/* *************分割*********** */

// 首页统计
export const  statisticalAll= params => {
  return getRequest("/yethan/register/statistical/getAllInfo", params);
};

// 首页刷新统计结果
export const  refreshStatisticAll= params => {
  return getRequest("/yethan/register/statistical/statisticalAll", params);
};

/* *************分割*********** */

// 考试详情统计
export const  getInfoStatic= params => {
  return getRequest("/yethan/register/statistical/getInfo", params);
};
// 考试详情刷新统计结果
export const  refreshInfoStatic= ksbmbh => {
  return getRequest(`/yethan/register/statistical/statisticalOne?ksbmbh=`+ksbmbh);
};

/* *************分割*********** */

// 成绩统计
export const  getCjkStatic= cjkid => {
  return getRequest(`/yethan/exam/statistical/getStatistics?cjkid=`+cjkid);
};
// 成绩刷新统计结果
export const  refreshCjkStatic= params => {
  return getRequest("/yethan/exam/statistical/refresh",params);
};


/* *************分割*********** */

// 开放的报名统计
export const  getRegisting= params => {
  return getRequest("/yethan/register/statistical/getRegisting",params);
};
